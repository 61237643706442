<template>
  <div class="flex flex-col font-Inter mb-5">
    <PageHeader>
      <div class="text-gray-900 py-3 text-2xl font-semibold">
        Gestion des caisses de vente
      </div>
    </PageHeader>

    <div class="px-10 py-4">
      <div class="flex">
        <el-button
          class="mr-2"
          type="primary"
          icon="el-icon-plus"
          @click="handleNewCashModal"
          >Créer</el-button
        >
        <el-input
          class="flex-1"
          placeholder="Rechercher par le nom "
          v-model="listQuery.name"
          @keydown.native.enter="fetchData()"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <div class="customer-white mt-10">
        <div class="list-pos">
          <skeleton-list
            class="bg-white shadow p-4"
            v-if="listLoading"
            :row="8"
          />

          <div
            v-else
            class="item-pos shadow-md"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="h-3/5 flex flex-col mb-2">
              <h1 class="item-pos-title">
                [{{ item.number }}] {{ item.title }}
              </h1>
              <p class="item-pos-desc">{{ item.description }}</p>
              <p class="item-pos-desc">
                Compte rattaché:
                {{ item.account_name }}
              </p>
            </div>
            <div class="h-1/5">
              <el-button
                v-if="item.status === 'CLOSE'"
                type="warning"
                style="width: 100%"
                >Disponible</el-button
              >
              <el-button
                v-if="item.status === 'OPEN'"
                type="success"
                style="width: 100%"
              >
                En cours d'utilisation ...</el-button
              >
            </div>

            <div class="h-1/5 mt-2 flex justify-between items-center">
              <el-button
                @click="handleUpdate(item)"
                type="text"
                icon="el-icon-edit"
                >Modifier</el-button
              >
              <router-link :to="`fiche/${item.number}`">
                <el-button type="text" icon="el-icon-document"
                  >Plus d'info</el-button
                >
              </router-link>
              <el-switch v-model="item.active" @change="handleEnabled(item.id)">
              </el-switch>
            </div>
          </div>
        </div>
      </div>

      <el-dialog
        :title="
          dialogStatus === 'add'
            ? 'Nouvelle caisse de vente'
            : 'Modification de la caisse de vente'
        "
        width="35%"
        top="9vh"
        :close-on-click-modal="false"
        :visible.sync="dialogVisiblePos"
      >
        <el-form
          :model="posForm"
          :rules="rulePos"
          ref="posRef"
          :label-position="labelPosition"
        >
          <el-form-item prop="title" label="Nom de la caisse">
            <el-input style="width: 100%" v-model="posForm.title"> </el-input>
          </el-form-item>

          <el-form-item prop="description" label="Description">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4 }"
              v-model="posForm.description"
            />
          </el-form-item>

          <el-form-item
            label="Compte pour reception des paiements "
            prop="account_number"
          >
            <el-select
              style="width: 100%"
              v-model="posForm.account_number"
              placeholder="Choisir le compte"
              clearable
              filterable
            >
              <el-option
                v-for="item in accounts"
                :key="item.account_id"
                :label="`${item.account_name}`"
                :value="item.numero"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Entrepôts" prop="warehouse_code">
            <el-select
              class="w-full"
              v-model="posForm.warehouse_code"
              placeholder="selectionner l'entrepôt "
            >
              <el-option
                v-for="item in warehouses"
                :key="item.code"
                :label="`${item.name}`"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div slot="footer" class="border-t pt-2 dialog-footer">
          <el-button
            type="primary"
            :loading="loadingButton"
            @click="
              dialogStatus === 'add'
                ? createData('posRef')
                : updateData('posRef')
            "
            >Enregistrer</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { fetchAccounts } from "@/api/account";
import { fetchWarehouse } from "@/api/stock";
import { getAllPos, enablePos, createPos, updatePos } from "@/api/sales2";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import PageHeader from "@/components/PageHeader";
import SkeletonList from "@/components/Skeleton/list";
export default {
  name: "POS",
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  components: { PageHeader, SkeletonList },
  data() {
    return {
      warehouses: [],
      options: [
        {
          value: "ALL",
          label: "Tous",
        },
        {
          value: "ACTIF",
          label: "Actif",
        },
        {
          value: "DESACTIF",
          label: "Désactif",
        },
      ],
      listLoading: false,
      loadingButton: false,
      dialogVisiblePos: false,
      dialogVisibleOpenPos: false,
      items: [],
      posNumbUpdate: "",
      dialogStatus: "add",
      accounts: [],
      labelPosition: "top",
      posForm: {
        title: "",
        description: "",
        account_numbers: "",
        warehouse_code: "",
      },

      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        enbled: undefined,
        name: undefined,
      },
      rulePos: {
        account_number: [
          {
            required: true,
            message: "Le compte est obligatoire",
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: "Le nom est obligatoire",
            trigger: "blur",
          },
        ],
        warehouse_code: [
          {
            required: true,
            message: "L'entrepôt est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    this.fetchAccounts();
    this.fetchWarehouse();
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.listLoading = true;
      await getAllPos(this.listQuery).then((res) => {
        setTimeout(() => {
          this.items = res.data.items;
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    async fetchAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async handleEnabled(id) {
      await enablePos(id)
        .then(() => {})
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "danger",
          });
        });
    },
    handleNewCashModal() {
      this.resetPosForm();
      this.dialogVisiblePos = true;
      this.$nextTick(() => {
        this.$refs["posRef"].clearValidate();
      });
    },

    createData(posRef) {
      this.$refs[posRef].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          createPos(this.posForm)
            .then((res) => {
              setTimeout(() => {
                this.loadingButton = false;
                this.items.unshift(res.data);
                this.$message({
                  message: "La caisse créee avec succès",
                  type: "success",
                });

                this.dialogVisiblePos = false;
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.dialogVisiblePos = false;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              }

              if (err.data.status === 490) {
                this.$message({
                  message: err.data.message,
                  type: "danger",
                });
              }
            });
        }
      });
    },
    handleUpdate(row) {
      this.posForm = Object.assign({}, row); // copy obj
      this.posNumbUpdate = row.number;
      this.dialogStatus = "update";
      this.loading = false;
      this.dialogVisiblePos = true;
      this.$nextTick(() => {
        this.$refs["posRef"].clearValidate();
      });
    },
    resetPosForm() {
      this.posForm = {
        title: "",
        description: "",
        account_numbers: "",
        warehouse_id: "",
      };
    },
    updateData(posRef) {
      this.$refs[posRef].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          updatePos(this.posForm, this.posNumbUpdate)
            .then(() => {
              setTimeout(() => {
                this.loading = false;

                this.dialogVisiblePos = false;
                this.$message({
                  title: "Success",
                  message: "Modifier avec succès",
                  type: "success",
                  duration: 2000,
                });
              }, 3000);
            })
            .catch((error) => {
              setTimeout(() => {
                if (error.status === 409 || error.status === 404) {
                  this.$message({
                    title: "Erreur",
                    message: error.data.message,
                    type: "error",
                    duration: 2000,
                  });
                }

                if (error.status === 400) {
                  this.$message({
                    title: "Erreur",
                    message: "Veuillez remplir les champs obligatoire",
                    type: "error",
                    duration: 2000,
                  });
                }
                this.loading = false;
              }, 1.5 * 1000);
            });
        }
      });
    },
    async fetchWarehouse() {
      await fetchWarehouse()
        .then((res) => {
          this.warehouses = res.data;
        })
        .catch((error) => {
          this.listLoading = false;
          this.$message.error(error.data.message);
        });
    },

    triggerOpenPOS(number) {
      //  this.$router.push(`/pos?caisse=${number}`);
      let routeData = this.$router.resolve({
        path: `/pos?caisse=${number}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
$color: #8faa92;
.list-pos {
  width: 100%;
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(4, 1fr);
  gap: 20px 5px;
}
.item-pos {
  display: flex;
  flex-direction: column;
  height: 280px;
  background: white;
  margin-right: 30px;
  padding: 16px;
  .item-pos-title {
    word-break: break-all;
    font-size: 1.3rem;
  }
  .item-pos-desc {
    font-size: 14px;
    margin-top: 6px;
    color: gray;
  }
}
.circle-ripple {
  background-color: #35ffc3;
  width: 0.1em;
  height: 0.1em;
  border-radius: 50%;
  animation: ripple 0.7s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($color, 0.3), 0 0 0 0.1em rgba($color, 0.3),
      0 0 0 0.3em rgba($color, 0.3), 0 0 0 0.5em rgba($color, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0.1em rgba($color, 0.3), 0 0 0 3em rgba($color, 0.3),
      0 0 0 0.5em rgba($color, 0.3), 0 0 0 8em rgba($color, 0);
  }
}
</style>
